import ApiService from '@/services/common/api.service'

const auth = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    updateCompanyUserService(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.put(`/auth/company-user-service`, params).then(
          (response) => {
            if (response.status === 204) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default auth
