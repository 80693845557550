import ApiService from '@/services/common/api.service'

const question = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getQuestions(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/auth/request-package?page=${params.page}&perPage=${params.perPage}&orderBy=${params.orderBy}&orderDirection=${params.orderDirection}&search=${params.search}`
        ).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default question
