import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import company from './modules/company'
import user from './modules/user'
import service from './modules/service'
import apps from './modules/apps'
import payments from './modules/payments'
import question from './modules/question'
import packageModule from './modules/packages'
import voucher from './modules/voucher'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    auth,
    company,
    user,
    service,
    apps,
    payments,
    question,
    packageModule,
    voucher,
  },
})

export default store
