import ApiService from '@/services/common/api.service'

const company = {
  namespaced: true,
  actions: {
    storeCompany(context, param) {
      return new Promise((resolve, reject) => {
        ApiService.post('/auth/company', param).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    fetchCompaniesDataByUser() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/company').then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    selectCompany(context, param) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          '/auth/company/select/' + param.id + '?service_id=' + param.serviceId
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('auth/setAccessToken', response.data.accessToken, {
                root: true,
              })
              context.commit(
                'auth/setRefreshToken',
                response.data.refreshToken,
                { root: true }
              )
              resolve(response)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    getCurrentCompany() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/company/get-current').then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    getCompanies() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/companies').then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    IndexActiveCompany(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/company/active-company', params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    IndexInactiveCompany(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/company/inactive-company', params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default company
