import ApiService from '@/services/common/api.service'

const packages = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getPackage(context, packageId) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/package?&idactive=${packageId}`).then(
          (response) => {
            if (response != 'paket is empty') {
              resolve(response.data.data)
            } else {
              resolve(null)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getPackageActive() {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/package/active`).then(
          (response) => {
            if (response.data.data.id != 0) {
              let packageDetail = {
                id: response.data.data.packageId,
                billingId: response.data.data.id,
                name: response.data.data.package.packageName,
                price: response.data.data.package.price,
                maxUsers: response.data.data.package.maxUsers,
                activeDate: response.data.data.activeDate,
                status: response.data.data.status,
              }
              resolve(packageDetail)
            } else {
              let packageDetail = {
                id: 0,
                name: '-',
                price: '-',
                maxUsers: '-',
                activeDate: '-',
                status: '-',
              }
              resolve(packageDetail)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getBilling(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/billing`, params).then(
          (response) => {
            // console.log('data :=', response.data)
            if (response != 'billing is empty') {
              resolve(response.data)
            } else {
              resolve(null)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    requestPackage(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/auth/package/ask-package`, params).then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default packages
