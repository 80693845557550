import Vue from 'vue'
import './assets/sass/app.scss'
import './registerServiceWorker'
import './plugins/validator'

import App from './App'
import './assets/sass/app.scss'

import FrontendKit from '@roketin-library/frontend-kit'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import router from './router'
import store from './store'
import mixins from './mixins'
import ApiService from '@/services/common/api.service'
import { ValidationProvider } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.config.productionTip = false

Vue.use(FrontendKit, {
  // platform: 'html',
})

Vue.use(VueSweetalert2)

ApiService.init()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.mixin(mixins)
