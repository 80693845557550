import ApiService from '@/services/common/api.service'
import JwtService from '@/services/common/jwt.service'
import router from '@/router'
import store from '@/store'

const auth = {
  namespaced: true,
  state: () => ({
    errors: null,
    message: null,
    user: {},
    loggedIn: !!JwtService.getToken(),
    accessToken: null,
    // loggedIn: true,
  }),
  getters: {
    currentUser(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.loggedIn
    },
    currentAccessToken(state) {
      return state.accessToken
    },
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/login', {
          // grant_type: 'password',
          // client_id: process.env.VUE_APP_CLIENT_ID,
          // client_secret: process.env.VUE_APP_CLIENT_SECRET,
          email: credentials.email,
          password: credentials.password,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAccessToken', response.data.accessToken)
              context.commit('setAuth', {
                user: response.data.user,
              })
              router.push({ path: credentials.redirect })
              resolve(response)
            } else if (response.status === 204) {
              reject(response)
            }
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/logout').then(
          (response) => {
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
        context.commit('purgeAuth')
        router.push({ path: '/auth/login' })
      })
    },
    register(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/register', {
          email: credentials.email,
          password: credentials.password,
        }).then(
          (response) => {
            router.push({ path: '/auth/login' })
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    forgotPassword(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/forgot-password', {
          email: credentials.email,
        }).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    resetPassword(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/reset-password', {
          token: credentials.token,
          userId: credentials.userId,
          password: credentials.password,
        }).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
              router.push({ path: credentials.redirect })
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    checkResetPasswordToken(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/check-reset-password-token', {
          token: credentials.token,
        }).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    checkActivateAccountToken(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/check-activate-account-token', {
          token: credentials.token,
        }).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getCurrentUser(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/current').then(
          (response) => {
            if (response.status == 200) {
              context.commit('setAuth', {
                user: response.data.data,
              })
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getAccessToken(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/get-access-token').then(
          (response) => {
            if (response.status == 200) {
              context.commit('setAccessToken', response.data.accessToken)
              context.commit('setRefreshToken', response.data.refreshToken)
              resolve(response)
            }
          },
          (error) => {
            store.dispatch('auth/logout')
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setError(state, error) {
      state.errors = error
    },
    setAuth(state, user) {
      state.loggedIn = true
      state.user = user
      state.errors = {}
    },
    setAccessToken(state, data) {
      state.accessToken = data
    },
    setRefreshToken(state, data) {
      JwtService.saveToken(data)
    },
    purgeAuth(state) {
      state.loggedIn = false
      state.user = {}
      state.errors = {}
      JwtService.destroyToken()
    },
  },
}

export default auth
