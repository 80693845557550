import ApiService from '@/services/common/api.service'

const auth = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getUsers(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/auth/user?page=${params.page}&per_page=${params.perPage}&order_by=${params.orderBy}&order_direction=${params.orderDirection}&search=${params.search}&serviceID=${params.serviceID}`
        ).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    storeUser(context, param) {
      return new Promise((resolve, reject) => {
        ApiService.post('/auth/user', param).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    inviteUsers(context, params) {
      console.log(params)
      return new Promise((resolve, reject) => {
        ApiService.post('/auth/user/invite-users', params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            console.log('err')
            console.log(error)
            reject(error)
          }
        )
      })
    },
    getUser(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/user/${params.id}`).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    updatePassword(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/auth/user/change-password`, params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    updateUser(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.put(`/auth/user/${params.id}`, params).then(
          (response) => {
            if (response.status === 204) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    deleteUser(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.delete(`/auth/user/${params.id}`).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default auth
