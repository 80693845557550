import axios from 'axios'
import JwtService from './jwt.service'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import * as qs from 'qs'
import store from '@/store'

const ApiService = {
  init() {
    axios.defaults.headers.post['Content-Type'] =
      'application/x-www-form-urlencoded'
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
  },

  reInitAuthorization() {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${store.getters['auth/currentAccessToken']}`
  },

  setHeaderMultipartFormData() {
    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  },

  query(resource) {
    return axios.get(resource)
  },

  get(resource, params) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'brackets' })
        },

        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
              console.log(error)
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status == 401) {
          this.init()
          store.dispatch('auth/getAccessToken').then(() => {
            this.reInitAuthorization()
            this.get(resource, params)
          })
        } else {
          // store.dispatch('auth/logout')
          throw err
        }
      })
  },

  getFile(resource, params) {
    return axios
      .get(`${resource}`, {
        params:
          params !== undefined ? snakecaseKeys(params, { deep: true }) : params,
        responseType: 'blob',
      })
      .catch((err) => {
        if (err.response.status == 401) {
          this.init()
          store.dispatch('auth/getAccessToken').then(() => {
            this.reInitAuthorization()
            this.getFile(resource, params)
          })
        } else {
          store.dispatch('auth/logout')
          throw err
        }
      })
  },

  post(resource, params) {
    return axios
      .post(`${resource}`, snakecaseKeys(params, { deep: true }), {
        transformResponse: [
          (data) => {
            if (data) {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            }
          },
        ],
      })
      .catch((err) => {
        console.log(err)
        if (err.response == undefined) {
          store.dispatch('auth/logout')
        }
        throw err
      })
  },

  postImage(resource, params) {
    return axios
      .post(`${resource}`, params, {
        headers: { 'Content-type': 'multipart/form-data' },
        transformResponse: [
          (data) => {
            if (data) {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            }
          },
        ],
      })
      .catch((err) => {
        console.log(err)
        if (err.response == undefined) {
          store.dispatch('auth/logout')
        }
        throw err
      })
  },

  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  put(resource, params, noSnakeCase) {
    return axios
      .put(
        `${resource}`,
        !noSnakeCase ? snakecaseKeys(params, { deep: true }) : params,
        {
          transformResponse: [
            (data) => {
              if (data) {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              }
            },
          ],
        }
      )
      .catch((err) => {
        if (err.response.status == 401) {
          this.init()
          store.dispatch('auth/getAccessToken').then(() => {
            this.reInitAuthorization()
            this.put(resource, params)
          })
        } else {
          // store.dispatch('auth/logout')
          throw err
        }
      })
  },

  delete(resource, params) {
    return axios
      .delete(resource, {
        params: params ? snakecaseKeys(params, { deep: true }) : null,
        transformResponse: [
          (data) => {
            if (data) {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            }
          },
        ],
      })
      .catch((err) => {
        if (err.response.status == 401) {
          this.init()
          store.dispatch('auth/getAccessToken').then(() => {
            this.reInitAuthorization()
            this.delete(resource, params)
          })
        } else {
          store.dispatch('auth/logout')
          throw err
        }
      })
  },
}

export default ApiService
