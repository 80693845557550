import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import ApiService from '../services/common/api.service'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.querySelector('html').style.overflow = 'auto'
  let isAuthenticated = store.getters['auth/isLoggedIn']
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    if (to.matched.some((record) => record.meta.redirect)) {
      next({ name: 'Login', query: { redirect: to.path } })
    } else {
      next({ name: 'Login' })
    }
  } else if (isAuthenticated) {
    var accessToken = store.getters['auth/currentAccessToken']
    if (accessToken == null) {
      await store.dispatch('auth/getAccessToken')
    }
    ApiService.reInitAuthorization()
    if (to.name === 'Login' || to.name === 'Registration') {
      // Go back to home if user is authenticated and want go to login/register
      next('/')
    } else {
      if (to.matched.some((record) => record.meta.requiresAuth2)) {
        await store.dispatch('auth/getCurrentUser')
        console.log(store.getters['auth/currentUser'].user.companyId)
        if (
          store.getters['auth/currentUser'].user.role != 'Owner' &&
          store.getters['auth/currentUser'].user.role != 'staff'
        ) {
          store.dispatch('auth/logout')
        }
        console.log('to meta')
        console.log(to.meta.requiresCompanyID1)
        if (
          to.meta.requiresCompanyID1 &&
          store.getters['auth/currentUser'].user.companyId != 1
        ) {
          next(false)
          next('/billing/package/dashboard')
        }
      }
      // if (to.matched.some((record) => record.meta.forAdmin)) {
      //   // Check if user is admin
      //   let user = store.getters['user/getUserInfo']

      //   if (user == null) {
      //     await store.dispatch('user/loadUserFromToken')
      //     user = store.getters['user/getUserInfo']
      //   }
      //   if (user.isAdmin) {
      //     next()
      //   } else {
      //     next('/')
      //   }
      // } else {
      //   // If route for everyone
      //   next()
      // }
    }
  }
  next()
})

const DEFAULT_TITLE = 'Space ID'
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
