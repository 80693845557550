import ApiService from '@/services/common/api.service'

const vouchers = {
  namespaced: true,
  state: () => ({
    // packageDetail: null,
  }),
  getters: {},
  actions: {
    // getPackageDetail1(context) {
    //   return new Promise((resolve, reject) => {
    //     ApiService.init()
    //     ApiService.get(`/auth/company/package`).then(
    //       (response) => {
    //         if (response.status === 200) {
    //           context.commit('setPackageDetail1', {
    //             packageDetail: response,
    //           })
    //           resolve(response.status)
    //         }
    //       },
    //       (error) => {
    //         reject(error)
    //       }
    //     )
    //   })
    // },
    createVoucher(context, params) {
      console.log('data voucher:', params)
      return new Promise((resolve, reject) => {
        ApiService.post('/auth/vouchers/', params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    updateVoucher(params) {
      return new Promise((resolve, reject) => {
        ApiService.put(`/auth/vouchers/${params.voucherId}`, params).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    indexVouchers() {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/vouchers`).then(
          (response) => {
            console.log(response.data)
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    indexVoucherRedeemed(context, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/vouchers/redeemed`, params).then(
          (response) => {
            console.log(response.data)
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    showVoucherRedeemed(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/vouchers/redeemed/${id}`).then(
          (response) => {
            console.log(response.data)
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    showVoucher(context, id) {
      console.log(id)
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/vouchers/${id}`).then((response) => {
          if (response.status === 200) {
            resolve(response)
          } else {
            reject(response)
          }
        })
      })
    },
    checkVoucher(context, code) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/auth/vouchers/check/${code}`).then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (response) => {
            reject(response)
          }
        )
      })
    },
    deleteVoucher(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.delete(`/auth/vouchers/${id}`).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.status)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    // setPackageDetail1(state, data) {
    //   state.packageDetail = data
    // },
  },
}

export default vouchers
