import ApiService from '@/services/common/api.service'

const service = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getCurrentUserService() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/service/get-current').then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    getAllService() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/service').then(
          (response) => {
            if (response.status === 200) {
              resolve(response)
            }
          },
          (error) => {
            console.log(error)
            reject(error)
          }
        )
      })
    },
    getCompanyService() {
      return new Promise((resolve, reject) => {
        ApiService.get('/auth/service/check-company-service').then(
          (response) => {
            if (response.status == 200) {
              resolve(response.data.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {},
}

export default service
